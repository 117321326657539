import * as React from 'react';
import {FormEvent, SyntheticEvent} from 'react';
import './LoginContainer.css';
import {Form, Grid, Image, InputOnChangeData, Message, Container, Segment, Flag} from 'semantic-ui-react';
import {RouteComponentProps} from 'react-router';
import i18n from "../../../../locale/i18n";
import authService from "../../services/AuthService";
import {appInfo} from '../../services/AppInfo';
import {withRouter} from "react-router-dom";

const logo = require('../../logo.png');
const translator = i18n.t.bind(i18n);

type LoginProps = {
    submitting: boolean,
    loading: boolean,
    userId: string,
    password: string,
    error: boolean,
    errorMsg: string
}

class LoginContainer extends React.Component<RouteComponentProps<{}>, LoginProps> {
    constructor(props: RouteComponentProps<{}>) {
        super(props);

        this.state = {
            submitting: false,
            loading: false,
            userId: '',
            password: '',
            error: false,
            errorMsg: ''
        };

        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.onChangeUser = this.onChangeUser.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);
        this.onChangeLanguage = this.onChangeLanguage.bind(this);
        this.redirectToDefaultPage = this.redirectToDefaultPage.bind(this);
    }

    redirectToDefaultPage = () => {
        window.location.reload();
        //this.props.history.push('/');
    };


    componentDidMount () {
        authService.checkIfLoggedIn().then(this.redirectToDefaultPage).catch(
            function() {
            }

        );
    }

    onChangeLanguage(language : string) {
        i18n.changeLanguage(language);
        this.forceUpdate();
    }

    onFormSubmit( event: FormEvent<HTMLElement>) {
        event.preventDefault( );
        if (this.state.submitting || this.state.loading) {
            return;
        }
        this.setState({
            submitting: true
        });
        authService.login({
            userId: this.state.userId,
            password: this.state.password
        })
            .then(this.redirectToDefaultPage)
            .catch(error => {
                this.setState({
                    submitting: false,
                    password: '',
                    error: true,
                    errorMsg: error
                });
            });
    }

    onChangeUser(event: SyntheticEvent<HTMLInputElement>, data: InputOnChangeData) {
        if (this.state.submitting) {
            return;
        }
        this.setState({ userId: data.value });
    }

    onChangePassword(event: SyntheticEvent<HTMLInputElement>, data: InputOnChangeData) {
        if (this.state.submitting) {
            return;
        }
        this.setState({ password: data.value });
    }

    render() {
        return (
            <Grid centered={true} verticalAlign="middle" className={"login-grid"}>
                <Grid.Row divided={true}>
                        <Grid.Column widescreen="4" mobile="15" tablet="7" computer="5" largeScreen="4">
                            <Image src={logo} alt="Logo" spaced={false} size="medium" centered={true}/>
                            <h2 className={"loginHeading"}>Sustain: Data</h2>

                        </Grid.Column>
                        <Grid.Column widescreen="3" mobile="15" tablet="7" computer="5" largeScreen="4">
                            <Segment>
                                    <Form loading={this.state.submitting} onSubmit={this.onFormSubmit} error={this.state.error}>
                                        <Message
                                            error={true}
                                            icon="warning"
                                            header={"Benutzername / Passwort falsch!"}
                                            content={translator(this.state.errorMsg)}
                                        />
                                        {/* when userId is email, add hint to browser for mobile users (text input causes space after dot when typing!) */}
                                        <Form.Input
                                            label={"Benutzername oder E-Mail"}
                                            icon="user"
                                            name="userId"
                                            type="string"
                                            required={true}
                                            value={this.state.userId}
                                            onChange={this.onChangeUser}
                                        />
                                        <Form.Input
                                            label={"Passwort"}
                                            icon="key"
                                            name="password"
                                            type="password"
                                            required={true}
                                            value={this.state.password}
                                            onChange={this.onChangePassword}
                                        />
                                        <Form.Button type="submit" content={"Login"}/>
                                    </Form>
                                </Segment>
                        </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}

export default withRouter(LoginContainer);
